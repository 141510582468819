<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
        <tbody><tr>
        <td><div align="center"><font size="+2">Splash Database</font></div></td>
        </tr>
		<tr>
			<td align="center"><a href="http://www.cs.columbia.edu/~kshitiz">Kshitiz Garg</a>,&nbsp;&nbsp;<a href="http://www.cs.columbia.edu/~gkguru">Guru Krishnan</a>,&nbsp;&nbsp;<a href="http://www.cs.columbia.edu/~nayar">Shree Nayar</a></td> 
		</tr>
            <tr><td><div align="left">
              <p align="left">
                </p><center><img width=800 src="https://cave.cs.columbia.edu/old/databases/splash/material_large.png"><br> <br> </center>
                <h3 align="left">Introduction</h3>
			We have measured the splashing behaviors
			of 22 different materials that are commonly found in the real world.
			These materials can be broadly classified as rough (e.g., wood and
			brick), smooth (e.g., marble and glass), flexible (e.g., silk and
			paper) and miscellaneous (e.g., water and moss). We have developed a
			stochastic model for splash distribution that builds upon empirical
			models previously developed in fluid dynamics and meteorology. Our
			model is simple and only requires 7 coefficients for generating
			splashes for head-on impact for a material. A more general model for
			generating splash for arbitrary impact angles (due to surface
			inclination or wind) requires 54 coefficients. The models of different
			materials may be combined to generate physically plausible splashes for
			novel materials that have not been measured. Our model is applicable
			for rendering splashes due to rain as well as water drops falling from
			large heights such as windowsills, trees, and rooftops. For more details, please refer to the following paper:<p></p><br>
				<blockquote>
				<div class="publication" data-v-0ff51178=""><b data-v-0ff51178="">"Material Based Splashing of Water Drops,"<br data-v-0ff51178=""></b><span data-v-0ff51178="">K. Garg, G. Krishnan and S.K. Nayar,<br data-v-0ff51178=""></span><span data-v-0ff51178="">Eurographics Symposium on Rendering,<br data-v-0ff51178=""></span><span data-v-0ff51178="">Jun. 2007<br data-v-0ff51178=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Garg_EGSR07.pdf" target="new" data-v-0ff51178="">PDF</a>] [<a href="reference?bid=218" class="url" data-v-0ff51178="">bib</a>] [<a href="copyright" class="url" data-v-0ff51178="">©</a>] <span data-v-0ff51178=""><a href="projects/categories/project?cid=Physics-Based+Vision&amp;pid=Material+Based+Splashing+of+Water+Drops" class="url" data-v-0ff51178=""> [Project Page]</a></span><br data-v-0ff51178=""></div>
				</blockquote>
			<br>
                <h3 align="left">Format of database</h3>
				<img src="https://cave.cs.columbia.edu/old/databases/splash/small_table.png" width="100%"><br><br>
				<p align="left">
				The coefficients of the splash models of four materials. The splash
				coefficients are specified as a row vector in the following order:
				(1, α, φ, αφ, α^2, φ^2, α^2φ, φ^2α, α^3, φ^3)
				where α is inclination angle and φ is azimuthal angle. The database can be downloaded as a zip file (<a href="https://cave.cs.columbia.edu/old/databases/splash/data_files.zip">data_files.zip</a>). The <a href="https://cave.cs.columbia.edu/old/databases/splash/coeff_file_format.txt">coeff_file_format</a> specifies the format of the coefficient files. </p><br>
                <h3 align="left">Crown Database</h3>
              <p align="left">
			<img src="https://cave.cs.columbia.edu/old/databases/splash/crown_measure.png"><br><br>
For low angles of inclination (head-on impact), some materials can
produce corona splashes with crowns that appear for a short duration.
The crown can produce noticeable effects when the splashes are viewed
up close. The height and the radius of the crown vary between
materials. We measure these parameters by taking a side-view image of
the crown for each material, such as the one in (a). These measurements
are used to scale the height and radius of the generic 3D crown model
shown in (b) during rendering. </p>
                <h3 align="left">Project Page</h3>
              <p align="left">The project page is located <a href="/projects/categories/project?cid=Physics-Based+Vision&pid=Material+Based+Splashing+of+Water+Drops">here</a>.</p>
            </div></td>
          </tr>
          <tr>
            <td><hr>
Last modified: 09/10/2007</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>